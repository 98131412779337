body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--gray-4);
  margin: 0;
  padding: 0;
}
h1 {
  font-size: var(--text-lg);
  font-weight: 700;
  font-family: 'Comic Sans MS';
  color: black;
}
h2 {
  font-size: var(--text-m);
  font-family: Garamond;
}
h3 {
}
p {
}
button {
  background-color: var(--blue-1);
  border-radius: 10%;
}
button:hover {
  outline: 1px solid var(--blue-4);
  box-shadow: 0px 0px 3px 2px var(--gray-3);
}
button:active {
  outline: 1px solid var(--blue-1);
  box-shadow: 0px 0px 3px 2px var(--blue-1);
}
a {
  text-decoration: none;
  color: var(--blue-2);
}
a:active {
  color: var(--red-2);
}
strong {
  font-family: 'comic sans ms';
  font-weight: 700;
}
em {
  font-weight: 500;
  color: var(--orange-2);
}
i-lg {
  font-weight: 500;
  font-style: oblique;
  color: var(--blue-1);
}
hr.vr-s {
  height: var(--text-sm);
}
hr.vr {
  height: var(--text-reg);
}
hr.vr-m {
  height: var(--text-reg);
}
hr.vr-lg {
  height: var(--text-lg);
}
hr.vr-xl {
  height: var(--text-xl);
}
s-txt {
  font-family: 'comic sans ms';
  color: var(--red-1);
  text-shadow: 1px 1px 1px var(--red-2);
}
